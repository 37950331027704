
















































































import ListingMixin from '../mixins/ListingMixin'
import ListingForm from '../components/ListingForm.vue'
import ListingEditionsPanel from '../components/ListingEditionsPanel.vue'
import ReleasesPanel from '../components/ReleasesPanel.vue'
import ListingPublishPanel from '../components/ListingPublishPanel.vue'
import ListingCommentsPanel from '../components/ListingCommentsPanel.vue'
import ListingLinksPanel from '../components/ListingLinksPanel.vue'
import ListingHistoryPanel from '../components/ListingHistoryPanel.vue'
import ListingContactsPanel from '../components/ListingContactsPanel.vue'
import ListingAlertsPanel from '../components/ListingAlertsPanel.vue'

export default ListingMixin.extend({
  components: {
    ListingForm,
    ListingEditionsPanel,
    ReleasesPanel,
    ListingPublishPanel,
    ListingCommentsPanel,
    ListingLinksPanel,
    ListingHistoryPanel,
    ListingAlertsPanel,
    ListingContactsPanel
  },
  methods: {
    onSubmit() {
      this.saveListing()
    },
    onReset() {
      this.discardListing()
    }
  },
  created() {
    this.getListing(+this.$route.params.id)
  },
  async beforeRouteLeave(to, from, next) {
    const message = 'You have unsaved changes. Are you sure you want to leave?'
    if (
      !this.hasChanges ||
      (await this.$confirm(message, { title: 'Unsaved Changes' }))
    ) {
      next()
    } else {
      next(false)
    }
  }
})
