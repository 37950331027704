




















































import { PropType } from 'vue'
import PublishedReferencesMixin from '../mixins/PublishedReferencesMixin'
import ListingPublishStatusLink from './ListingPublishStatusLink.vue'
import ListingPublishStatusForm from './ListingPublishStatusForm.vue'
import ListingPublishStatusIcon from './ListingPublishStatusIcon.vue'
import ListingPublishStatusModel from '../models/ListingPublishStatusModel'

export default PublishedReferencesMixin.extend({
  props: {
    value: Object as PropType<ListingPublishStatusModel>,
    disabled: Boolean,
    canPublish: Boolean,
    showApproval: Boolean,
    showForm: Boolean
  },
  components: {
    ListingPublishStatusLink,
    ListingPublishStatusForm,
    ListingPublishStatusIcon
  },
  watch: {
    'value.$_status': {
      handler() {
        this.getPublishedReferences(
          this.value.listingId,
          this.value.environmentId
        )
      },
      immediate: true
    }
  },
  computed: {
    lastPublishedBy(): string {
      const dateString = this.$formatter.datetime(this.value.publishedDate)
      if (dateString) {
        return `Last published on ${dateString}`
      }
      return 'Unpublished'
    },
    lastApprovedBy(): string {
      const approvers = this.value.approvers
      if (approvers) {
        return `Last approved by ${approvers}`
      }
      return ''
    },
    canUnpublish(): boolean {
      return !!this.value.publishedDate
    }
  }
})
