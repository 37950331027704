import ViewModel from '@/models/ViewModel'
import ListingEventChange from './ListingEventChange'
import IEvent from './IEvent'
import EventColors from './EventColors'

export default class ListingEvent extends ViewModel implements IEvent {
  id: string
  name = 'Saved'
  modifiedBy: string
  modifiedDate: string
  comment = ''
  isKeyEvent = false
  colors: EventColors = new EventColors()
  changes: ListingEventChange[] = []

  constructor(event: IEvent) {
    super()
    Object.assign(this, event)

    // replace the name if user is import process user
    if (RegExp(/^func-omaha-/i).test(event.modifiedBy)) {
      this.name = 'Saved by Import Process'
    }

    // process changes
    this.changes = event.changes.map(change => {
      const model = new ListingEventChange(change)
      model.modifiedDate = this.modifiedDate
      model.subject = model.subject.replace(/\/Listings\/([^/]*)\//, '')
      model.namedSubject = model.namedSubject.replace(/\/Listings\/([^/]*)\//, '')
      return model
    })

    this.applyMappings()
  }

  applyMappings() {
    this.changes.forEach(change => {
      change.applyMapping(this)
    })
    this.changes = this.changes.filter(x => !x.hidden)
  }
}
