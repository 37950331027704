






















































import Vue from 'vue'
import EventChange from '@/areas/events/components/EventChange.vue'
import EventItem from '@/areas/events/components/EventItem.vue'
import ListingEvent from '../models/ListingEvent'

export default Vue.extend({
  components: {
    EventChange,
    EventItem
  },
  props: {
    expanded: Boolean,
    selected: Boolean,
    event: Object as () => ListingEvent
  }
})
