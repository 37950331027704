








import Vue, { PropType } from 'vue'
import { PublishStatusEnum } from '../models/PublishStatusEnum'

export default Vue.extend({
  props: {
    value: Number as PropType<PublishStatusEnum>
  },
  computed: {
    borderColor(): string {
      switch (this.value) {
        case PublishStatusEnum.Current:
          return 'green darken-1'
        case PublishStatusEnum.UpdateNeeded:
          return 'green darken-1'
        case PublishStatusEnum.Unpublished:
          return 'grey darken-1'
        default:
          return 'grey darken-1'
      }
    },
    color() {
      switch (this.value) {
        case PublishStatusEnum.Current:
          return 'green lighten-2'
        case PublishStatusEnum.UpdateNeeded:
          return 'white'
        case PublishStatusEnum.Unpublished:
          return 'white'
        default:
          return 'grey lighten-2'
      }
    },
    icon(): string {
      switch (this.value) {
        case PublishStatusEnum.Current:
          return 'fa-check'
        default:
          return ''
      }
    }
  }
})
