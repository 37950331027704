import ODataMixin from '@/mixins/ODataMixin'
import ODataResult from '@/models/ODataResult'
import IInfiniteLoadEvent from '@/models/IInfiniteLoadEvent'
import ListingEvent from '../models/ListingEvent'
import IODataQuery from '@/models/IODataQuery'
import IEvent from '../models/IEvent'

export default ODataMixin.extend({
  data() {
    return {
      events: new ODataResult<ListingEvent>()
    }
  },
  methods: {
    async getEventsByListingId(listingId: number, query: IODataQuery = {}) {
      this.$store.commit('Events/setSelected', [])
      query.$expand = 'changes'
      query.$orderby = 'modifiedDate desc'
      query.$top = 20

      const response = await this.get<IEvent>(`Listings(${listingId})/Transactions`, query)
      this.events.count = response.count
      this.events.request = response.request
      this.events.value = response.value
        .map(x => new ListingEvent(x))
        .filter(x => x.changes.length)
    },
    async getMoreEvents(e: IInfiniteLoadEvent) {
      this.getMore(this.events, e, (item) => new ListingEvent(item))
    }
  }
})
