















import Vue from 'vue'

export default Vue.extend({
  props: {
    value: Array
  },
  methods: {
    onInput(items: any[]) {
      this.$emit(
        'input',
        items.map((item) => {
          if (typeof item === 'string') {
            return { id: 0, keyword: item }
          }
          return item
        })
      )
    }
  }
})
