import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[(_vm.links.length === 1)?_c('div',[_c('a',{attrs:{"href":_vm.links[0].url,"target":"_blank"}},[_vm._v(_vm._s(_vm.links[0].name))]),_c('CopyButton',{attrs:{"small":"","text":_vm.links[0].url}})],1):(_vm.links.length > 1)?_c('a',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.environment)+" "),_c('i',{staticClass:"fa fa-caret-down"})]):_c('span',[_vm._v(_vm._s(_vm.environment))])])]}}])},[(_vm.links.length > 1)?_c(VList,_vm._l((_vm.links),function(link){return _c(VListItem,{key:link.name,attrs:{"href":link.url,"target":"_blank"}},[_c(VListItemTitle,[_vm._v(_vm._s(link.name))]),_c(VListItemAction,[_c('CopyButton',{attrs:{"small":"","text":link.url}})],1)],1)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }