import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardText,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c('h4',{staticClass:"mb-n1"},[_vm._v(_vm._s(_vm.value.name))]),_vm._l((_vm.rolesByName),function(roles,roleName){return _c('div',{key:roleName},[_vm._v(" "+_vm._s(roleName)+" "),(roles.some(function (x) { return !!x.sourceDataId; }))?[_c('ul',_vm._l((roles),function(role){return _c('li',{key:role.$_uuid},[_c('router-link',{attrs:{"to":{
                    name: 'SourceData',
                    query: { id: role.sourceDataId }
                  },"target":"_blank"}},[_vm._v(_vm._s(role.$_sourceDataName))])],1)}),0)]:_vm._e()],2)})],2),_c(VCol,{staticClass:"shrink text-no-wrap"},[(_vm.value.listingContactRoles.some(function (x) { return !!x.sourceDataId; }))?[_c(VIcon,{attrs:{"color":"grey lighten-1","small":"","left":"","title":"Linked to source data record"}},[_vm._v("$link")])]:[_c(VBtn,{attrs:{"disabled":_vm.disabled,"icon":"","small":"","title":"Edit"},on:{"click":function($event){return _vm.$emit('edit', _vm.value)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("$edit")])],1),_c(VBtn,{attrs:{"disabled":_vm.disabled,"icon":"","small":"","title":"Delete"},on:{"click":function($event){return _vm.$emit('delete', _vm.value)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("$trash")])],1)]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }