




























































































import FormMixin from '@/mixins/FormMixin'
import FamilySelect from '@/areas/listingHierarchies/components/FamilySelect.vue'
import GroupSelect from '@/areas/listingHierarchies/components/GroupSelect.vue'
import ProductSelect from '@/areas/listingHierarchies/components/ProductSelect.vue'
import SupportPolicySelect from '@/areas/supportPolicies/components/SupportPolicySelect.vue'
import ListingSupportDate from './ListingSupportDate.vue'
import ListingKeywordsCombobox from './ListingKeywordsCombobox.vue'

export default FormMixin.extend({
  components: {
    FamilySelect,
    GroupSelect,
    ProductSelect,
    SupportPolicySelect,
    ListingSupportDate,
    ListingKeywordsCombobox
  },
  props: {
    disabled: Boolean
  },
  methods: {
    onSupportDateChange(supportDate, index) {
      if (supportDate.attributeOverrideTypeId === 0) {
        supportDate.overrideValue = null
      }
      this.form.listingSupportDates.splice(index, 1, supportDate)
    }
  }
})
