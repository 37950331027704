import ODataMixin from '@/mixins/ODataMixin'
import IPublishedReference from '../models/IPublishedReference'

export default ODataMixin.extend({
  data() {
    return {
      publishedReferences: [] as IPublishedReference[]
    }
  },
  computed: {
    environmentName(): string {
      return this.publishedReferences[0]?.environment
    }
  },
  methods: {
    async getPublishedReferences(listingId: number, environmentId: number) {
      this.publishedReferences = (await this.get<IPublishedReference>(`/Listings(${listingId})/PublishedReferences?$filter=environmentId eq ${environmentId}`)).value
    }
  }
})
