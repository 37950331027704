
















import FormMixin from '@/mixins/FormMixin'
import AlertTypeSelect from '@/areas/alerts/components/AlertTypeSelect.vue'

export default FormMixin.extend({
  components: {
    AlertTypeSelect
  }
})
