

















































import Panel from '@/components/Panel.vue'
import PagedResult from '@/components/PagedResult.vue'
import ListingComment from './ListingComment.vue'
import CommentsMixin from '../mixins/CommentsMixin'

export default CommentsMixin.extend({
  props: {
    disabled: Boolean
  },
  components: {
    Panel,
    ListingComment,
    PagedResult
  },
  created() {
    this.getComments()
  }
})
