































import Vue, { PropType } from 'vue'
import CopyButton from '@/components/CopyButton.vue'
import IPublishedReference from '../models/IPublishedReference'
import ListingPublishStatusModel from '../models/ListingPublishStatusModel'

export default Vue.extend({
  components: {
    CopyButton
  },
  props: {
    value: {
      type: Array as PropType<IPublishedReference[]>,
      required: true
    },
    publishStatus: {
      type: Object as PropType<ListingPublishStatusModel>
    }
  },
  computed: {
    environment(): string {
      return this.publishStatus?.environment?.name
    },
    links(): any[] {
      const refs = this.value
      const links = refs?.map((ref) => {
        const name = `${ref.environment} on ${ref.externalSystem}`
        return {
          name,
          url: ref.url
        }
      })
      return links
    }
  }
})
