<template>
  <div>
    <slot />
    <InfiniteScroll
      v-if="result && result.count"
      :identifier="infiniteId"
      @infinite="loadMore"
    />
  </div>
</template>

<script>
import InfiniteScroll from '@/components/InfiniteScroll'

export default {
  components: {
    InfiniteScroll
  },
  props: {
    result: {
      type: Object,
      default: () => {
        return {
          count: 0,
          value: []
        }
      }
    },
    infiniteId: null
  },
  methods: {
    loadMore(e) {
      this.$emit('load-more', e)
    }
  }
}
</script>
