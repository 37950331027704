import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"1"}},[_c(VAvatar,{attrs:{"color":_vm.color,"size":"28"}},[_c(VIcon,{attrs:{"x-small":"","title":_vm.title}},[_vm._v(_vm._s(_vm.icon))])],1)],1),_c(VCol,{attrs:{"cols":"11"}},[_c(VCard,{attrs:{"flat":"","color":_vm.color}},[_c(VCardText,{staticClass:"caption pa-1"},[_vm._t("default")],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }