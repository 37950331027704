













import Vue from 'vue'
import OverrideDatePicker from '@/areas/overrides/components/OverrideDatePicker.vue'
import IListingSupportDate from '../models/IListingSupportDate'

export default Vue.extend({
  components: {
    OverrideDatePicker
  },
  props: {
    value: Object as () => IListingSupportDate
  },
  methods: {
    onChange(e) {
      const supportDate = this.$clone(this.value)
      supportDate.attributeOverrideTypeId = e.overrideTypeId
      this.$set(supportDate, 'overrideValue', e.overrideDate)
      this.$emit('input', supportDate)
    }
  }
})
