import ListingEventChange from './ListingEventChange'
import ListingEvent from './ListingEvent'

export default class EventMapping {
  regex: RegExp
  apply: (change: ListingEventChange, event: ListingEvent) => void

  constructor(regex: RegExp, apply: (change: ListingEventChange, event: ListingEvent) => void) {
    this.regex = regex
    this.apply = apply ?? (() => { })
  }
}
