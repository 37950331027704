














import AlertTypesMixin from '../mixins/AlertTypesMixin'

export default AlertTypesMixin.extend()
