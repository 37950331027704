











import Vue from 'vue'
import Clipboard from '@/utils/Clipboard'

export default Vue.extend({
  props: {
    text: String
  },
  methods: {
    copy() {
      Clipboard.copyText(this.text)
    }
  }
})
