











































import Vue from 'vue'
import { mapGetters } from 'vuex'
import { DataTableHeader } from 'vuetify'
import IListingReleaseDataSource from '../models/IListingReleaseDataSource'
import ISupportPolicy from '@/areas/supportPolicies/models/ISupportPolicy'

export default Vue.extend({
  props: {
    value: Array as () => IListingReleaseDataSource[],
    supportPolicyId: Number,
    disabled: Boolean
  },
  computed: {
    ...mapGetters('SupportPolicies', ['getSupportPolicyById']),
    headers(): DataTableHeader[] {
      const headers: DataTableHeader[] = [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Source Id',
          value: 'dataSourceUniqueId'
        },
        {
          text: 'Source',
          value: 'dataSourceName'
        }
      ]

      const supportPolicy = this.getSupportPolicyById(
        this.supportPolicyId
      ) as ISupportPolicy

      const dateTypes = supportPolicy.supportPolicyDateTypes

      for (const dateType of dateTypes) {
        const header: DataTableHeader = {
          text: dateType.label.replace('Date', '').trim(),
          value: null
        }

        if (dateType.isSupportStartDate) {
          header.value = 'supportStartDate'
        }
        if (dateType.isSupportEndDate1) {
          header.value = 'supportEndDate1'
        }
        if (dateType.isSupportEndDate2) {
          header.value = 'supportEndDate2'
        }
        if (dateType.isSupportEndDate3) {
          header.value = 'supportEndDate3'
        }
        headers.push(header)
      }

      headers.push({
        text: 'Editions',
        value: 'edition'
      })
      headers.push({
        text: '',
        value: 'actions',
        width: 50,
        align: 'end'
      })

      return headers
    }
  }
})
