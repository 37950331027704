import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":"","tile":"","color":_vm.event.colors.primary}},[_c(VCardText,{class:['pa-2', _vm.event.colors.primary]},[_c(VRow,{staticClass:"body-2",attrs:{"dense":""}},[_c(VCol,{staticClass:"shrink"},[_c('div',{attrs:{"title":"Highlight Changes"}},[_c(VCheckbox,{staticClass:"pa-0 mt-1",attrs:{"dark":!!_vm.event.colors.secondary,"color":_vm.event.colors.secondary,"value":_vm.selected,"hide-details":"","dense":""},on:{"click":function($event){return _vm.$emit('select', !_vm.selected)}}})],1)]),_c(VCol,[_c('h4',{staticClass:"mb-n1"},[_vm._v(_vm._s(_vm.event.name))]),_c('i',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(_vm._f("datetime")(_vm.event.modifiedDate))+" - "+_vm._s(_vm.event.modifiedBy))])]),(_vm.event.changes.length)?_c(VCol,{staticClass:"shrink"},[_c(VBtn,{attrs:{"icon":"","small":"","color":_vm.event.colors.secondary,"title":_vm.expanded ? 'Hide Details' : 'Show Details'},on:{"click":function($event){return _vm.$emit('expand', !_vm.expanded)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v(_vm._s(_vm.expanded ? '$collapse' : '$expand'))])],1)],1):_vm._e()],1),(_vm.event.comment)?_c('EventItem',{attrs:{"icon":"$comment","title":"Comment","color":_vm.event.colors.secondary}},[_vm._v(_vm._s(_vm.event.comment))]):_vm._e(),(_vm.expanded)?_vm._l((_vm.event.changes),function(change){return _c('EventChange',{key:change.id,attrs:{"change":change,"color":_vm.event.colors.secondary}})}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }