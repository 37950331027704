






import Vue from 'vue'
import ListingAlertForm from './ListingAlertForm.vue'
import ListingNoteModel from '../models/ListingNoteModel'

export default Vue.extend({
  components: {
    ListingAlertForm
  },
  props: {
    value: Object as () => ListingNoteModel
  },
  data() {
    return {
      form: null
    }
  },
  computed: {
    title(): string {
      return this.value?.$_uuid ? 'Edit Alert' : 'Create Alert'
    }
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    onSubmit() {
      this.$emit('submit', this.form || this.value)
    }
  }
})
