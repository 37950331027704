import Vue from 'vue'
import IAlertType from '../models/IAlertType'

export default Vue.extend({
  computed: {
    loadingAlertTypes(): boolean {
      return this.$store.getters['AlertTypes/loading']
    },
    alertTypes(): IAlertType[] {
      return this.$store.getters['AlertTypes/all']
    }
  },
  methods: {
    getAlertTypeById(id: number) {
      return this.alertTypes.find(x => x.id === id)
    }
  }
})
