












































import ListingEventsMixin from '@/areas/events/mixins/ListingEventsMixin'
import Panel from '@/components/Panel.vue'
import PagedResult from '@/components/PagedResult.vue'
import Event from '@/areas/events/components/Event.vue'
import ListingEvent from '@/areas/events/models/ListingEvent'
import ListingModel from '../models/ListingModel'

export default ListingEventsMixin.extend({
  components: {
    Panel,
    Event,
    PagedResult
  },
  props: {
    value: Object as () => ListingModel
  },
  data() {
    return {
      expanded: []
    }
  },
  watch: {
    changeHash: {
      handler() {
        this.getListingEvents()
      },
      immediate: true
    }
  },
  computed: {
    selected: {
      get(): ListingEvent[] {
        return this.$store.getters['Events/selected']
      },
      set(selected: ListingEvent[]) {
        this.$store.commit('Events/setSelected', selected)
      }
    },
    changeHash(): string {
      const listingChangeHash = this.value.concurrency
      const listingPublishChangeHash = this.value.listingPublishStatuses
        .map((x) => x.listingHash)
        .join()
      return listingChangeHash + listingPublishChangeHash
    }
  },
  methods: {
    expandAll() {
      this.expanded = this.events.value
    },
    collapseAll() {
      this.expanded = []
    },
    async getListingEvents() {
      await this.getEventsByListingId(this.value.id)
    }
  }
})
