import Vue from 'vue'
import OverrideType from '../models/OverrideType'
import IAttributeOverrideType from '../models/IAttributeOverrideType'

export default Vue.extend({
  computed: {
    overrideTypesLoading(): boolean {
      return this.$store.getters['OverrideTypes/loading']
    },
    overrideTypes(): OverrideType[] {
      const types = this.$store.getters['OverrideTypes/overrideTypes'] as IAttributeOverrideType[]
      return types.map(x => new OverrideType(x))
    }
  },
  methods: {
    getOverrideTypeById(id: number) {
      return this.overrideTypes.find(x => x.id === id)
    }
  }
})
