import ViewModel from '@/models/ViewModel'
import IAttributeOverrideType from './IAttributeOverrideType'

export default class OverrideType extends ViewModel implements IAttributeOverrideType {
  id: number
  uniqueId: string
  modifiedDate: string
  modifiedBy: string
  concurrency: string
  name: string
  description: string
  comments: string
  constructor(overrideType: IAttributeOverrideType) {
    super()
    Object.assign(this, overrideType)
  }
}
