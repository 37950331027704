












import OverrideTypesMixin from '../mixins/OverrideTypesMixin'

export default OverrideTypesMixin.extend()
