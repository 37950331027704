

















































































import Vue, { PropType } from 'vue'
import Panel from '@/components/Panel.vue'
import ListingLinkDialog from './ListingLinkDialog.vue'
import ListingSelectDialog from './ListingSelectDialog.vue'
import Draggable from 'vuedraggable'
import EventHighlight from '@/areas/events/components/EventHighlight.vue'
import ListingLinkModel from '../models/ListingLinkModel'
import ListingModel from '../models/ListingModel'

export default Vue.extend({
  components: {
    Panel,
    ListingLinkDialog,
    ListingSelectDialog,
    Draggable,
    EventHighlight
  },
  props: {
    disabled: Boolean,
    value: Object as PropType<ListingModel>
  },
  data() {
    return {
      activeItem: null,
      itemToCopy: null as ListingLinkModel
    }
  },
  methods: {
    onCopy(e) {
      this.$emit('copy', this.itemToCopy, e)
      this.itemToCopy = null
    },
    reorderLinks(links: ListingLinkModel[]) {
      const listing = this.$clone(this.value)
      links.forEach((link, index) => {
        this.$set(link, 'sortOrder', index)
      })
      listing.listingLinks = links
      this.$emit('input', listing)
    },
    addLink() {
      this.activeItem = new ListingLinkModel()
    },
    saveLink(link: ListingLinkModel) {
      const listing = this.$clone(this.value)
      const links = listing.listingLinks
      const index = links.findIndex((x) => x.$_uuid === link.$_uuid)

      if (index >= 0) {
        links.splice(index, 1, link)
      } else {
        link.sortOrder = links.length
        links.push(link)
      }

      this.$emit('input', listing)
      this.activeItem = null
    },
    async deleteLink(link: ListingLinkModel) {
      if (
        await this.$confirm('Are you sure you want to delete the Link?', {
          title: 'Delete Link?'
        })
      ) {
        const listing = this.$clone(this.value)
        const index = listing.listingLinks.findIndex(
          (x) => x.$_uuid === link.$_uuid
        )
        listing.listingLinks.splice(index, 1)
        this.$emit('input', listing)
      }
    }
  }
})
