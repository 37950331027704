


















































































































import FormMixin from '@/mixins/FormMixin'
import ReleaseSelect from './ReleaseSelect.vue'
import ReleaseDataSourcesTable from './ReleaseDataSourcesTable.vue'
import OverrideDatePicker from '@/areas/overrides/components/OverrideDatePicker.vue'
import AttributeOverrideTypesSelect from '@/areas/overrides/components/OverrideTypesSelect.vue'
import { DataTableHeader } from 'vuetify'
import { orderBy } from 'lodash-es'
import IListingRelease from '../models/IListingRelease'
import IListingEdition from '../models/IListingEdition'
import IListingReleaseDataSource from '../models/IListingReleaseDataSource'

export default FormMixin.extend({
  props: {
    value: {
      type: Array as () => IListingRelease[],
      required: true
    },
    supportPolicyId: {
      type: Number,
      required: true
    },
    disabled: Boolean
  },
  components: {
    ReleaseSelect,
    ReleaseDataSourcesTable,
    AttributeOverrideTypesSelect,
    OverrideDatePicker
  },
  data() {
    return {
      expanded: [],
      options: {
        sortBy: ['supportStartDate'],
        sortDesc: [true]
      },
      editionsToDelete: [] as IListingEdition[],
      releaseToDelete: null as IListingRelease,
      dataSourceToDelete: null as IListingReleaseDataSource
    }
  },
  computed: {
    headers(): DataTableHeader[] {
      return [
        {
          text: 'Release Name',
          value: 'release'
        },
        {
          text: 'Publish',
          value: 'publish',
          align: 'center'
        },
        {
          text: 'Internal Only',
          value: 'isPrivate',
          align: 'center'
        },
        {
          text: 'Release Start',
          value: 'supportStartDate'
        },
        {
          text: 'Release End',
          value: 'supportEndDate'
        },
        {
          text: '',
          value: 'actions',
          width: 120,
          align: 'end'
        }
      ]
    }
  },
  methods: {
    expandItem(item) {
      this.expanded = this.expanded.length ? [] : [item]
    },
    setStartDate(release: IListingRelease, e) {
      release.supportStartDateAttributeOverrideTypeId = e.overrideTypeId
      this.$set(release, 'supportStartDate', e.date)
      this.$set(release, 'supportStartDateOverride', e.overrideDate)
    },
    setEndDate(release: IListingRelease, e) {
      release.supportEndDateAttributeOverrideTypeId = e.overrideTypeId
      this.$set(release, 'supportEndDate', e.date)
      this.$set(release, 'supportEndDateOverride', e.overrideDate)
    },
    onSort(items: IListingRelease[], sortBy: string[], sortDesc: boolean[]) {
      const $sortBy = [...sortBy]
      const $sortDesc = [...sortDesc]

      items.forEach((item) => {
        item.$_supportStartDateSort =
          item.supportStartDateOverride || item.supportStartDate
      })

      if ($sortBy[0] === 'supportStartDate') {
        $sortBy[0] = '$_supportStartDateSort'
      }

      return orderBy(
        items,
        $sortBy,
        $sortDesc.map((x) => (x ? 'desc' : 'asc'))
      )
    }
  }
})
