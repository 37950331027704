import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Panel',{attrs:{"title":"History","loading":_vm.loading && !_vm.loadingMore,"skeletonLoader":"list-item-three-line@15"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VBtn,{attrs:{"small":"","icon":"","title":"Expand All"},on:{"click":function($event){return _vm.expandAll()}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("$expandAll")])],1),_c(VBtn,{attrs:{"small":"","icon":"","title":"Collapse All"},on:{"click":function($event){return _vm.collapseAll()}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("$collapseAll")])],1)]},proxy:true}])},[_c('div',{staticClass:"history-panel"},[_c('PagedResult',{attrs:{"result":_vm.events},on:{"load-more":_vm.getMoreEvents}},[_c(VDataIterator,{attrs:{"items":_vm.events.value,"expanded":_vm.expanded,"item-key":"$_uuid","hide-default-footer":"","disable-pagination":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var isExpanded = ref.isExpanded;
var isSelected = ref.isSelected;
var expand = ref.expand;
var select = ref.select;
return _vm._l((items),function(event,index){return _c('div',{key:event.$_uuid},[_c('Event',{attrs:{"event":event,"expanded":isExpanded(event),"selected":isSelected(event)},on:{"expand":function($event){return expand(event, $event)},"select":function($event){return select(event, $event)}}}),(index < _vm.events.value.length - 1)?_c(VDivider):_vm._e()],1)})}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }